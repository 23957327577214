.cover {
    background-color: #2FE2ED;
  }
  
  .cover-image {
    width: 100%;
    margin-top: 50px;
    margin-bottom: -20px;
  }
  
      .cover-support {
        font-family: 'Quicksand';
        font-weight: normal;
        font-size: 26px;
        line-height: 35px;
        /* color: black; */
        color: white;
      }

      .cover-button {
        margin-top: -20px;
      }
  
      .cover-impact-button {
        font-size: 14px;
        line-height: 24px;
        padding: 3px 40px 3px 40px;
      }

@media only screen and (max-width: 630px) {

  .cover-button {
    margin-top: 5px;
  }

}