.foodprint-ready {
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 35px;
    margin-top: 50px;
}

.foodselected-and-editbutton {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    width: 80%;
    max-width: 1200px;
}

    .food-selected-title {
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 35px;
    }

    .edit-foodprint {
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 35px;
        color: #F2C94C;
        background-color: transparent;
        cursor: pointer;
    }

        .edit-foodprint:hover {
            box-shadow: none;
        }

.categories-selected {
    background: #8435CE;
    border: 1px solid #333333;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-top: 10px;
    width: 80%;
    max-width: 1200px;
}

.four-categories {
    display: flex;
    justify-content: space-evenly;
}

.category-selected {
    background-color: #BB6BD9;
    width: 100%;
    /* margin: 1px; */
    border-right: 1px solid #333333;
    border-bottom: 1px solid #333333;
    padding: 20px;
}

    .categories-selected-left {
        border-radius: 10px 0px 0px 0px;
    }

    .categories-selected-right {
        border-radius: 0px 10px 0px 0px;
        border-right: none;
    }

        .category-selected-title {
            font-family: Quicksand;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 5px;
        }

        .food-selected {
            display: flex;
            /* align-items: baseline; */
        }
    
    .foodprint-nutritionalvalues {
        padding: 20px;
        font-family: Quicksand;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        justify-content: center;
    }

        .foodprint-nutritionalvalues-title {
            font-weight: bold;
        }
        
.weeklyfoodprint-title {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    margin-top: 60px;
    width: 80%;
    max-width: 1200px;
}

.weeklyfoodprint-cards {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 80%;
    max-width: 1200px;
}

.weeklyfoodprint-impactcard {
    background: #FFFFFF;
    border: 1px solid #333333;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color:  #333333;
    text-align: center;
    width: 30%;
    margin-top: 10px;
}

    .impactcard {
        background: #FFECB3;
        border-radius: 10px 10px 0px 0px;
        padding: 20px;
    }

    .co2-color {
        background: #FFECB3;
    }

    .water-color {
        background: #B2EBF2;
    }

    .land-color {
        background: #DCEDC8;
    }

        .impactcard-title {
            font-family: Quicksand;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 40px;
        }

        .impactcard-subtitle {
            font-family: Quicksand;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
        }

    .topthree-section {
        margin: 20px 20px 50px 20px;
    }

    .topthree-title {
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #333333;
    }

        .polluting-impact-selection {
            display: flex;
            padding: 25px 20px 0px 20px;
        }

        .polluting-impact {
            margin: 0px 20px 0px 20px;
            background: #FAFAFA;
            border: 1px solid #666666;
            box-sizing: border-box;
            border-radius: 15px;
            width: 90%;
            height: 22px;
        }

            .pollution-impact-filling {
                height: 20px;
                border-radius: 15px;
                min-width: 7%;
                width: 7%;
            }

        .polluting-impact-values {
            display: flex;
            font-family: Quicksand;
            font-style: normal;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: #666666;
            justify-content: center;
            margin-top: 2px;
        }

        .polluting-impact-percentage {
            font-weight: bold;
        }

.weeklyfoodprint-support {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    width: 80%;
    max-width: 1200px;
    margin-top: 10px;
}

    .arrow-desktop {
        height: 60px;
        margin: 30px;
    }
    .arrow-mobile {
        height: 60px;
        display: none;
    }

    .foodprint-solution {
        margin-top: 30px;
        width: 77%;
        max-width: 1165px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px 20px 20px;
        background: #BB6BD9;
        border: 1px solid #333333;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
    }

    .alternatives-group-foodprint {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

        .foodprint-alternative-title-section {
            margin-left: 5%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

            .foodprint-alternative-titleandimage {
                display: flex;
                justify-content: center;
            }

                .foodprint-alternative-title {
                    font-family: Quicksand;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 28px;
                    line-height: 35px;
                    text-align: center;
                }
            
            .foodprint-alternative-optimisenutrvalue {
                display: flex;
            }

.foodprint-datasource-section {
    margin-top: 20px;
    width: 100%;
}


/* @media only screen and (max-width: 1350px) {

    .arrow-desktop {
        display: none;
    }
    .arrow-mobile {
        display: block;
    }

    .foodprint-solution {
        flex-direction: column;
        padding-bottom: 30px;
    }

    .foodprint-alternative-title-section {
        margin: 20px 0px 20px 0px;
    }

} */

@media only screen and (max-width: 1300px) { 

    .four-categories {
        flex-direction: column;
    }
    .categories-selected-left {
        border-radius: 10px 10px 0px 0px;
    }
    .categories-selected-right {
        border-radius: 0px 0px 0px 0px;
    }
    .category-selected {
        width: auto;
        border-right: none;
    }
    .foodprint-nutritionalvalues {
        flex-direction: column;
    }
    .foodprint-nutritionalvalues-title {
        margin-bottom: 10px;
    }

    .weeklyfoodprint-impactcard {
        margin-top: 20px;
        width: 32%;
    }
    .foodselected-and-editbutton{
        width: 90%;
    }
    .categories-selected {
        width: 90%;
    }
    .weeklyfoodprint-title {
        width: 90%;
    }
    .weeklyfoodprint-cards {
        width: 90%;
    }
    .weeklyfoodprint-support {
        width: 90%;
    }
    .foodprint-solution {
        margin-top: 30px;
        width: 87%;
        max-width: 1165px;
    }
}

@media only screen and (max-width: 1100px) { 

    .arrow-desktop {
        display: none;
    }
    .arrow-mobile {
        display: block;
    }

    .foodprint-solution {
        flex-direction: column;
        padding-bottom: 30px;
    }

    .foodprint-alternative-title-section {
        margin: 20px 0px 20px 0px;
    }

}

@media only screen and (max-width: 950px) { 

    .weeklyfoodprint-cards {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .weeklyfoodprint-impactcard {
        margin-top: 20px;
        width: 90%;
    }

}

@media only screen and (max-width: 700px) { 

    .foodprint-ready {
        font-size: 24px;
        line-height: 20px;
    }
    .food-selected-title {
        font-size: 24px;
        line-height: 20px;
    }
    .edit-foodprint {
        font-size: 24px;
        line-height: 20px;
    }
    .weeklyfoodprint-title {
        font-size: 24px;
        line-height: 20px;
    }
    .weeklyfoodprint-cards {
        margin-top: 0px;
    }

}

@media only screen and (max-width: 500px) { 

    .foodprint-ready {
        font-size: 20px;
    }
    .food-selected-title {
        font-size: 20px;
    }
    .edit-foodprint {
        font-size: 20px;
    }
    .weeklyfoodprint-title {
        font-size: 20px;
    }

    .weeklyfoodprint-impactcard {
        width: 90%;
    }

    .foodprint-solution {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-right: 0px;
        width: 90%;
    }

    .foodselected-and-editbutton {
        width: 90%;
    }
    .categories-selected {
        width: 90%;
    }
    .weeklyfoodprint-title {
        width: 90%;
    }
    .weeklyfoodprint-support {
        width: 90%;
    }
    .weeklyfoodprint-impactcard {
        width: 90%;
    }
}

@media only screen and (max-width: 400px) { 

    .foodprint-ready {
        font-size: 16px;
    }
    .food-selected-title {
        font-size: 16px;
    }
    .edit-foodprint {
        font-size: 16px;
    }
    .weeklyfoodprint-title {
        font-size: 16px;
    }
    
}