.foodprint-form {
    display: flex;
    margin-top: 20px;
}

    .foodprint-input {
        width: 58px;
        height: 46px;
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        text-transform: uppercase;
        color: #444444;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border: 0px;
        margin: 0px; 
    }

    /* removing the input number default increment buttons from Chrome */
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; 
      }
    /* removing the input number default increment buttons from Mozilla */
    input[type=number] { 
        -moz-appearance: textfield;
        appearance: textfield;
        margin: 0; 
    }

    .foodprint-input-more {
        width: 48px;
        height: 48px;
        font-family: Quicksand;
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        color: #444444;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px 8px 8px 0px;
        cursor: pointer;
    }

    .foodprint-input-less {
        width: 48px;
        height: 48px;
        font-family: Quicksand;
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        color: #444444;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px 0px 0px 8px;
        cursor: pointer;
    }

        .foodprint-input-less-inactive {
            color: rgba(68, 68, 68, 0.25);
            cursor: default;
        }

        .foodprint-input-less-inactive:hover {
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          }
    
    .foodprint-measure {
        width: 20px;
        height: 48px;
        font-family: Quicksand;
        font-style: normal;
        font-size: 20px;
        line-height: 25px;
        text-align: left;
        color: #444444;
        background: #FFFFFF;
        box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.25);
        /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */
        border: 0px;
    }

        .foodprint-measure-text{
            margin-top: 12px;
        }

    .foodprint-foodlabel{
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        color: #FFFFFF;
        margin: 10px;
    }

@media only screen and (max-width: 600px) {

    .foodprint-input{
        width: 50px;
        font-size: 16px;
        line-height: 20px;
        border-radius: 0px 0px 0px 0px;
        margin: 0px;
        padding: 1px;
    }

    .foodprint-input-more {
        width: 40px;
        font-size: 16px;
        line-height: 20px;
    }

    .foodprint-input-less {
        width: 40px;
        font-size: 16px;
        line-height: 20px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    }
    
    .foodprint-measure{
        font-size: 16px;
        line-height: 20px;
    }

    .foodprint-measure-text{
        margin-top: 14px;
    }
    
    .foodprint-foodlabel{
        max-width: 110px;
        font-size: 16px;
        line-height: 20px;
        margin-top: 12px;
        margin-bottom: 0px;
    }

}