.solution {
    font-family: Quicksand;
    color: #FFFFFF;
    margin-top: 50px;
    margin-bottom: 150px;
  }

  .solution-title-section {
    width: 900px;
  }

  .solution-title {
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 50px;
    display: inline;
  }

    .solution-title-bold {
      font-weight: bold;
    }

    .uppercase {
      text-transform: uppercase;
    }

  .alternatives {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .alternative-recommended{
    background-color: #6FCF97;
    border-radius: 15px;
    padding: 5px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #219653;
    margin-top: 40px;
  }
    .alternative-recommended-foodprint{
      background-color: #6FCF97;
      border-radius: 15px;
      padding: 5px;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #219653;
      margin-top: 20px;
    }

    .alternative-recommended-text {
        padding: 5px;
    }

    .alternative {
        background-color: #8C3BD9;
        border-radius: 10px;
        color: #FFFFFF;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        width: 900px;
    }
      .alternative-foodprint {
        background-color: #8C3BD9;
        border-radius: 10px;
        color: #FFFFFF;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        width: 600px;
        display: flex;
        align-items: center;
        padding: 10px;
      }

    .alternative-items {
        display: flex;
        font-size: 36px;
        line-height: 50px;
    }
      .alternative-items-foodprint {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 18px;
        line-height: 35px;
      }

    .alternative-amount {
      font-weight: normal;
      display: inline;
    }

    .alternative-not-rec {
        margin-top: 40px;
    }
      .alternative-not-rec-foodprint {
        margin-top: 20px;
      }

    .alternative-grams{
      background-color: #9B51E0;
      font-style: normal;
      font-weight: normal;
      border-radius: 10px 10px 0px 0px;
      padding: 30px;
    }
      .alternative-grams-foodprint {
        background-color: none;
        font-style: normal;
        font-weight: normal;
        border-radius: 10px 10px 0px 0px;
        /* padding: 20px; */
        padding: 20px 0px 20px 0px;
        display: flex;
        justify-content: center;
        width: 40%;
      }
        .alternative-grams-foodprint-nutr {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
          .nutrvalue-foodprint-flex {
            display: flex;
          }
            .nutrvalue-foodprint {
              font-size: 16px;
              margin-top: -10px;
            }

        .alternative-impact {
            font-size: 23px;
            line-height: 29px;
            font-size: 16px;
            padding: 30px 30px 15px 30px;
        }

        .warning {
          color: #F2C94C;
        }

        .same-impact {
          font-style: normal;
          font-weight: normal;
        }

        .solution-calculation {
          font-family: "QuickSand";
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          background-color: transparent;
          border-color: transparent;
          color: #FFFFFF;
          padding-left: 30px;
          padding-bottom: 30px;
        }

        .solution-calculation:hover {
          box-shadow: none;
          text-decoration: underline;
          cursor: pointer;
        }

@media only screen and (max-width: 1100px) {

  .alternative-foodprint {
    width: 760px;
  }

  .alternative-grams-foodprint {
    width: 50%;
  }

}
        

@media only screen and (max-width: 1000px) {
  
  .solution-title-section {
    width: 750px;
  }
  
  .alternative {
    width: 750px;
  }

  .alternative-foodprint {
    width: 600px;
  }
  .alternative-grams-foodprint {
    width: 40%;
  }


}


@media only screen and (max-width: 900px) {

  .alternative-items {
    justify-content: center;
  }

}

@media only screen and (max-width: 800px) {

  .solution-title-section {
    width: 620px;
  }
  
  .solution-title {
    font-size: 30px;
    line-height: 40px;
  }
  
  .alternative {
    width: 600px;
    font-size: 28px;
  }

  .alternatives {
    font-size: 25px;
    margin-top: 30px;
  }

  .alternative-foodprint {
    flex-direction: column;
    width: 400px;
    padding-bottom: 20px;
  }
  .alternative-grams-foodprint {
    padding-bottom: 0px;
    width: auto;
  }
  
}

@media only screen and (max-width: 700px) {

  .solution-title-section {
    width: 550px;
  }

  .solution-title {
    font-size: 28px;
    line-height: 35px;
  }
  
  .alternative {
    width: 550px;
    font-size: 25px;
  }

  .alternatives {
    font-size: 22px;
    margin-top: 20px;
    line-height: 30px;
  }
  
}

@media only screen and (max-width: 630px) {

  .solution-title-section {
    width: 500px;
  }

  .solution-title {
    font-size: 25px;
    line-height: 35px;
  }
  
  .alternative {
    width: 500px;
    font-size: 23px;
  }

  .alternatives {
    font-size: 20px;
    margin-top: 20px;
    line-height: 30px;
  }
  
}

@media only screen and (max-width: 600px) {

  .solution-title-section {
    width: 450px;
  }

  .solution-title {
    font-size: 23px;
    line-height: 35px;
  }

  .alternative-items {
    font-size: 23px;
    line-height: 35px;
  }
  
  .alternative {
    width: 450px;
    font-size: 23px;
  }

  .alternatives {
    font-size: 20px;
    margin-top: 20px;
    line-height: 30px;
  }

  .alternative-foodprint {
    width: 350px;
  }
  
}

@media only screen and (max-width: 500px) {

  .solution-title-section {
    width: 400px;
  }

  .solution-title {
    font-size: 22px;
    line-height: 35px;
  }
  
  .alternative {
    width: 400px;
    font-size: 23px;
  }

  .alternatives {
    font-size: 20px;
    margin-top: 20px;
    line-height: 30px;
  }
  
}

@media only screen and (max-width: 450px) {

  .solution-title-section {
    width: 350px;
  }

  .solution-title {
    font-size: 26px;
    line-height: 35px;
  }

  .alternative-items {
    font-size: 20px;
    line-height: 35px;
  }
  
  .alternative {
    width: 350px;
    font-size: 23px;
  }

  .alternatives {
    font-size: 20px;
    margin-top: 20px;
    line-height: 30px;
  }

  .alternative-foodprint {
    width: 330px;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }

}

@media only screen and (max-width: 400px) {

  .solution-title-section {
    width: 300px;
  }

  .solution-title {
    font-size: 23px;
    line-height: 35px;
  }

  .alternative-items {
    font-size: 14px;
    line-height: 35px;
  }
  
  .alternative {
    width: 290px;
    font-size: 23px;
  }

  .alternatives {
    font-size: 20px;
    margin-top: 20px;
    line-height: 30px;
  }

  .alternative-foodprint {
    width: 310px;
  }
  
  
}

@media only screen and (max-width: 375px) {

  .solution-title-section {
    width: 290px;
  }

  .solution-title {
    font-size: 21px;
    line-height: 35px;
  }
  
  .alternative {
    width: 290px;
    font-size: 20px;
  }

  .alternatives {
    font-size: 20px;
    margin-top: 20px;
    line-height: 30px;
  }

  .alternative-impact {
    font-size: 14px;
    margin-top: 10px;
  }

  .alternative-foodprint {
    width: 280px;
  }
  
}

@media only screen and (max-width: 345px) {

  .solution-title {
    font-size: 21px;
    line-height: 35px;
  }
  
  .alternative {
    font-size: 16px;
  }

  .alternatives {
    font-size: 20px;
    margin-top: 20px;
    line-height: 30px;
  }

  .alternative-recommended{
    font-size: 13px;
  }

  .alternative-impact {
    font-size: 12px;
  }

  .alternative-foodprint {
    width: 265px;
  }
  
}