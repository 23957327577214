/* @import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap'); */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:bold,normal');
@import url('https://fonts.googleapis.com/css?family=Quicksand:bold,normal');

.red-home-background {
  background: #EB5757;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  color: white;
}

  .red-home-text {
    font-family: 'Quicksand';
    font-weight: 800;
    margin: 70px 20px 20px 20px;
    text-align: center;
    /* width: 70%;
    max-width: 900px; */
  }

    .red-home-title {
      font-size: 44px;
      line-height: 55px;
    }

    .red-home-support {
      font-size: 20px;
      line-height: 25px;
      font-weight: normal;
    }

.header {
  display: flex;
  justify-content: center;
  color: #4F4F4F;
  margin-bottom: 30px;
}

.corona {
  width: 60%;
  max-width: 400px;
  margin-bottom: 100px;
}

.current{
  text-shadow: 0 0 .65px #333, 0 0 .65px #333;
}

.hide {
  display: none;
}

body {
  background-color:#FFFFFF;
  display: flex;
  flex-direction: column;
  color: black;
  font-family: 'Quicksand';
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

  .page-title {
    font-family: 'Quicksand';
    font-weight: 800;
    font-size: 60px;
    line-height: 82px;
    color: #333333;
    /* margin: 80px 20px 20px 20px; */
    margin: 80px 80px 20px;
  }

  .cover-title {
    margin-top: 40px;
    color: white;
  }

  .section-title {
    font-family: 'Quicksand';
    font-weight: bold;
    font-size: 36px;
    line-height: 24px;
  }

.impact-button {
  cursor: pointer;
  font-family: 'Quicksand';
  font-weight: bold;
  color: white;
  border: 1px dotted #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  font-family: 'Quicksand';
  outline: none;
  transition: 0.2s;
  background: #BB6BD9;
  /* border-radius: 8px; */
  border-radius: 4px;
  padding: 5px 10px 5px 10px;
}

  .impact-button-impactpage {
    /* color: white; */
    background: #9B51E0;
    /* border: 1px dotted #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 5px 10px 5px 10px; */
  }

  .unit-text {
    display: inline;
  }

button:hover {
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  transition: 0.2s;
}

a:link, a:visited, a:active {
  text-decoration: none;
  color: #4F4F4F;
  font-weight: normal;
}

a:hover {
  /* text-decoration: underline; */
  color: #4F4F4F;
}

.beefvstofu {
  width: 60%;
  max-width: 820px;
  margin-top: 100px;
}

.foodcomparisondesktop {
  width: 90%;
  max-width: 1200px;
  margin-bottom: 30px;
}

.beefvstofumobile {
  display: none;
}

.home-note {
  margin: 30px 20px 120px 20px;
  font-family: Quicksand;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.foodprint-note {
  margin: 30px 20px 120px 20px;
  font-family: Quicksand;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  width: 80%;
  max-width: 1200px;
}

  .note-bold {
    display: inline;
    font-weight: bold;
  }

  .note-content {
    display: inline;
  }

/* comparisons */

/* .pollution {
  margin: 20px 0px 0px 0px;
} */

.impact-image {
  margin-top: 20px;
  width: 100%;
  max-width: 239px;
}

.same-as {
  font-size: 14px;
  line-height: 21px;
  margin: 20px 0px 10px 0px;
}

.comparison-section {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
}
  
  .comparison-element {
    display: flex;
    align-items: center;
    max-width: 239px;
    margin-top: 15px;
  }

    .comparison-image {
      height: 50px;
    }

    .comparison-text {
      margin-left: 10px;
    }

      .comparison {
        font-family: 'Quicksand';
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
      }

      .comparison-support {
        font-size: 14px;
        line-height: 21px;
        color: #828282;
      }

@media only screen and (max-width: 1300px) {

  .foodprint-note {
    width: 90%;
  }
  
}

@media only screen and (max-width: 1000px) {

  .header {
    color: #4F4F4F;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    text-align: center;
  }

  .nav {
    color: #4F4F4F;
    margin-top: 70px;
  }

  .beefvstofu {
    width: 80%;
  }

  .red-home-text {
    width: 80%;
  }

}

@media only screen and (max-width: 768px) {

  .impact-image {
    display: none;
  }

  .comparison-element {
    width: 100%;
    max-width: none;
  }

  .beefvstofu {
    display: none;
  }

  .foodcomparisondesktop {
    display: none;
  }

  .beefvstofumobile {
    display: block;
    display: initial;
    width: 90%;
    margin-top: 100px;
  }

  .red-home-title {
    font-size: 32px;
    line-height: 40px;
  }

  .red-home-support {
    font-size: 20px;
    line-height: 25px;
  }

}


@media only screen and (max-width: 630px) {

  .page-title {
    font-size: 48px;
    line-height: 60px;
    width: auto;
  }

  .section-title {
    font-size: 25px;
  }

}


@media only screen and (max-width: 460px) {

  .page-title {
    font-size: 35px;
    line-height: 50px;
    margin: 80px 20px 20px 20px;
  }

  .section-title {
    font-size: 25px;
    line-height: 35px;
  }
  
}


@media only screen and (max-width: 350px) {

  .page-title {
    font-size: 32px;
    line-height: 45px;
  }

}