.home-sections {
    display: flex;
    flex-direction: column;
    padding: 0px 300px;
    align-items: center;
}

.home-first-section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 850px;
    margin-top: 50px;
    justify-content: space-between;
}

.home-first-section-text{
    display: flex;
    flex-direction: column;
}

.home-title {
    font-weight: 800;
    font-size: 36px;
    line-height: 49px;
}

.home-text {
    font-size: 20px;
    line-height: 27px;
    margin-top: 10px;
}

.protectland-button {
    color: white;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    background-color: #00BFA6;
    margin: 20px 0px 0px 0px;
    border-radius: 4px;
    border: 2px solid #000000;
    padding: 10px 5px;
    width: 380px;
    cursor: pointer;
}

.home-supporting-text {
    margin-top: 5px;
    font-size: 16px;
    line-height: 22px;
    color: #828282;
}

.home-first-image {
    height: 180px;
}


.home-goal-section {
    background-color: rgba(0, 191, 166, 0.1);
    margin-top: 30px;
    padding: 30px 70px;
}
.goal-status {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 15px;
    width: 700px;
}
.goal-stauts-content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
    .goal-status-content-left {
        display: flex;
        align-items: flex-end;
    }
    .current-number {
        font-weight: 800;
        font-size: 24px;
        line-height: 22px;
        color: #007767;
        margin-right: 6px;
    }
    .goal-description {
        font-size: 16px;
        line-height: 22px;
    }
    .goal-reference {
        font-size: 16px;
        line-height: 22px;
        text-align: right;
    }

    .goal-bar-background {
        width: 740px;
        height: 32px;
        background: #17E8CC;
        border-radius: 50px;
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .goal-bar-progress {
        width: 182px;
        height: 24px;
        background: #007767;
        border-radius: 50px;
        margin-left: 5px;
    }

.section-separation {
    max-width: 1000px;
    margin: 80px 0px;
}

.home-second-section-groups {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 850px;
}
.second-section-group {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.second-section-image {
    height: 180px;
}
.home-second-section-belowgroups {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.third-section-group {
    display: flex;
    align-items: center;
    margin-bottom: 100px;
    flex-wrap: wrap;
    width: 850px;
}
.third-section-group-image {
    height: 230px;
}
.third-section-group-text {
    margin-left: 50px;
    max-width: 430px;
}

.third-section-googlemaps-group {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.googlemapsaddress {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}
.viewinmaps {
    margin-left: 10px;
}
.home-googlemaps-image {
    height: 100px;
    margin-bottom: 10px;
}


.howitworks-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 850px;
}
.howitworks-group {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 200px;
}
.howitworks-image {
    height: 100px;
}

.money-explenation {
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 850px;
}
.onedaycost{
    border-radius: 4px;
    border: 2px solid #000000;
    width: 350px;
    margin-top: 20px;
}
    .onedaycost-text {
        padding: 20px;
    }
    .day-title {
        margin-right: 10px;
    }
    .onedaycost-text-title {
        display: flex;
        align-items: baseline;
    }
    .one-day-price {
        font-size: 36px;
        line-height: 49px;
        text-align: center;
        background: #E3F8F5;
        border-radius: 4px;
        margin-top: 20px;
    }
    .onedaycost-button {
        color: white;
        font-weight: 800;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
        background-color: #00BFA6;
        margin: 10px 0px 0px 0px;
        border-radius: 4px;
        border-top: 2px solid #000000;
        padding: 10px 5px;
        cursor: pointer;
    }
    .oneday-space {
        margin-top: 20px;
    }
.money-spending {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pie-image {
    margin-top: 70px;
    height: 200px;
}

@media only screen and (max-width: 1000px) {

    .home-sections {
        padding: 0px;
    }
    .home-title {
        margin-top: 20px;
    }
    .money-spending-text {
        margin-top: 60px;
    }
    .home-first-section, .home-second-section-groups,
    .third-section-group, .howitworks-section, .money-explenation{
        max-width: 450px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .home-goal-section {
        padding: 30px 30px;
    }
    .protectland-button {
        width: 380px;
    }
    .goal-stauts-content {
        flex-direction: column;
        align-items: flex-start;
    }
    .goal-reference {
        margin-top: 25px;
    }
    .goal-status {
        width: 400px;
    }
    .goal-bar-background {
        width: 480px;
    }
    /* .goal-bar-progress {
        width: 182px;
    } */
    .section-separation {
        max-width: 100%;
    }

    .second-section-group {
        width: 300px;
    }
    .third-section-group-image {
        height: 230px;
    }
    .third-section-group-text {
        margin-left: 50px;
        max-width: 430px;
    }
    .howitworks-group {
        width: 200px;
    }
    .howitworks-image {
        height: 100px;
    }
    .onedaycost{
        width: 350px;
    }
}

@media only screen and (max-width: 540px) {

    .home-first-section, .home-second-section-groups,
    .third-section-group, .howitworks-section, 
    .money-explenation, .onedaycost{
        max-width: 300px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .home-goal-section {
        padding: 10px 10px;
        max-width: 300px;
    }
    /* .goal-stauts-content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    } */
    .goal-status-content-left {
        flex-direction: column;
        align-items: flex-start;
        height: 50px;
    }

    .goal-status {
        width: 300px;
    }
    .goal-bar-background {
        width: 300px;
    }
    /* .goal-bar-progress {
        width: 100px;
    } */
    .protectland-button {
        width: 290px;
    }
    .home-supporting-text {
        font-size: 15px;
    }
    .third-section-group-image {
        height: 130px;
    }

}