/* .impact-button-impacttform {
    margin-left: 30px;
}  */
.form-3level {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 25px;
  justify-content: space-around;
}
  
.impact-button-homepage-form {
    width: 100%;
}

.form-home {
    max-width: 840px;
    margin-right: 20px;
    margin-left: 20px;
    /* background: #BB6BD9; */
    background-color: #F2F2F2;
    border-radius: 20px;
    padding: 20px;
}
  
.form-impact {
    margin-right: 20px;
    margin-left: 20px;
    background: #F2F2F2;
    border-radius: 20px;
    padding: 10px;
}
  
.form-title {
    padding-top: 30px;
    padding-bottom: 10px;
}

.form-support {
    font-family: 'Quicksand';
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    padding-top: 10px;
    padding-bottom: 30px;
}

/* .form-input-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
} */

    .form-input-section-impactpage {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: column;
    }

    .form-amountandunit {
      /* border: 3px dotted #BDBDBD; */
      border-radius: 8px;
      background-color: #E0E0E0;
    }

    .form-input {
        font-family: 'Quicksand';
        font-weight: bold;
        border: 1px dotted #BDBDBD;
        border-radius: 8px;
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: normal;
        background-color: #FFFFFF;
        font-size: 24px;
        line-height: 40px;
        padding-top: 10px;
        padding-bottom: 10px;
        /* margin-bottom: 15px; */
        cursor: pointer;
    }
  
      input:focus, select:focus {
        border-color: #BB6BD9;
        outline: none;
      }
    
    .form-type {
      width: 830px;
      margin: 10px;
      -moz-appearance:none; /* Firefox */
      -webkit-appearance:none; /* Safari and Chrome */
      background: url(../images/arrow.svg) no-repeat right white;
      appearance: none;
      padding: 10px;
      text-align-last: center;
    }
  
    .form-number {
      width: 260px;
      margin-right: 5px;
      text-align: right;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 8px 0px 0px 8px;
    }
  
    .form-select {
      margin: 0px 25px 0px 25px;
      appearance: none;
      /* background-color: #F2F2F2; */
      /* color: #4F4F4F; */
      font-family: 'Quicksand';
      font-weight: bold;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 40px;
      padding-top: 10px;
      padding-bottom: 10px;
      /* margin-bottom: 15px; */
    }
  
    .form-button {
      font-size: 24px;
      line-height: 32px;
      padding: 15px 78px 15px 78px;
      margin-bottom: 20px;
    }
    
    /* .form-button-position-impact {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 20px;
      margin-bottom: 25px;
      justify-content: space-around;
    } */

    .form-button-old-calculation {
      color: #4F4F4F;
      background: #F2F2F2;
      border: 1px dotted #BDBDBD;
    }

    .form-button-new-calculation {
      color: white;
      background: #BB6BD9;
      border: 1px dotted #FFFFFF;
    }
  
  .form-image {
    width: 100%;
    height: 120px;
  }


  @media only screen and (max-width: 1000px) {

    .form-home {
        margin: 0px;
    }

  }

  @media only screen and (max-width: 900px) {

    .form-type {
      max-width: 750px;
    }

    .form-number {
      width: 200px;
    }

    .impact-button-impactpage-form {
      width: 400px;
      font-size: 22px;
    }

}

@media only screen and (max-width: 820px) {

  .form-type {
    max-width: 630px;
  }

  .form-number {
    width: 110px;
  }

  .impact-button-impactpage-form {
    width: 350px;
    font-size: 19px;
  }

}

@media only screen and (max-width: 700px) {

.form-3level {
  display: block;
}

  .form-type {
    width: 100%;
    margin: 0px;
  }

  .form-number {
    width: 50%;
  }

  .form-select {
    margin: 0px 25px 0px 25px;
  }

  .impact-button-impactpage-form {
    width: 100%;
    margin-top: 20px;
    font-size: 19px;
  }

  .form-button {
    padding: 15px 20px 15px 20px;
  }

}

@media only screen and (max-width: 430px) {
  
  .form-number {
    width: 40%;
  }

}

@media only screen and (max-width: 400px) {
    
  .form-input {
    font-size: 16px;
  }

  .form-select {
    font-size: 16px;
  }

}


@media only screen and (max-width: 367px) {
  
  .form-number {
    width: 29%;
  }

}







/* @media only screen and (max-width: 460px) {

    .form-impact {
        margin-right: 10px;
        margin-left: 10px;
      }
    
    .form-input {
        border: 3px dotted #BDBDBD;
        border-radius: 8px;
        font-size: 16px;
        margin: 0px;
        margin-bottom: 10px;
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .form-number {
        margin-right: 5px;
        text-align: center;
        padding-left: 4px;
    }

    .form-select {
        text-align: center;
        font-size: 16px;
    }

    .form-button {
        font-size: 15px;
        width: 100%;
        margin: 0px 0px 20px 0px;
        padding: 15px;
    }

    .form-button-position-impact {
        flex-direction: column;
    }

} */