.menu-impact-button {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
}

.menu {
    display: flex;
    flex-direction: row;
    /* justify-content: flex-end; */
    align-items: center;
    position: absolute;
    top: 20px;
    right: 20px;
    /* box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 8px 8px;
    background-color: #FFFFFF;
    width: 900px; */
}

.logo {
    /* display: flex;
    justify-content: flex-start;
    padding: 10px;
    margin-right: 200px; */
    position: absolute;
    top: 10px;
    left: 20px;
  }

    .logoicon {
      height: 44px;
      width: 44px;
      margin-right: 10px;
    }

.white {
    color: white;
}

.desktop {
    display: flex;
    font-size: 14px;
    margin-right: 25px;
    list-style-type: none;
}

.hamburger, .mobile {
    display: none;
}

@media only screen and (max-width: 1000px) {

    .mobile {
        display: block;
    }

    .menu {
        display: flex;
        justify-content: flex-start;
        background-color: transparent;
        box-shadow: none;
        width: 100%;
    }

    .desktop {
        display: none;
    }

    .hamburger {
        display: block;
        float: right;
        cursor: pointer;
        position: absolute;
        top: 1.1em;
        right: 0;
        margin-right: 1em;
    }

    .logo {
        margin-right: 0px;
    }

}


@media only screen and (max-width: 400px) {

    .logo {
        /* padding: 5px; */
        margin-right: 120px;
    }

}