.foodprint {
    font-family: Quicksand;
    color: #FFFFFF;
    text-align: left;
}

.step0-background {
    background-color: #9B51E0;
    width: 100%;
}

.step1-background {
    background-color: #27AE60;
    width: 100%;
}

.step2-background {
    background-color: #C64F4F;
    width: 100%;
}

.step3-background {
    background-color: #F2994A;
    width: 100%;
}

.step4-background {
    background-color: #2F80ED;
    width: 100%;
}

.foodprint-step0-alignment {
    display: flex;
    padding-top: 200px;
    justify-content: center;
    flex-wrap: wrap;
}

    .foodprint-step0-text {
        display: flex;
        flex-direction: column;
        width: 430px;
    }

    .foodprint-image {
        height: 376px;
        margin-left: 40px;
      }

.foodprint-alignment {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

.foodprint-intro {
    margin-top: 30px;
}

    .foodprint-intro-title {
        font-family: Quicksand;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
    }

    .progress-overview {
        display: flex;
    }

        .progress-box {
            width: 122px;
            height: 5px;
            background: rgba(255, 255, 255, 0.25);
            border-radius: 10px;
            margin: 10px 5px 0px 0px;
        }

        .progress-box-active {
            background-color: white;
        }
    
    .step-name {
        margin-top: 10px;
        font-family: Quicksand;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        display: flex;
    }

        .step-name-bold {
            font-weight: bold;
        }

    .step-image {
        margin-top: 50px;
        height: 100px;
    }

    .foodprint-calltoaction {
        margin-top: 20px;
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        width: 500px;
    }

.FoodprintForms {
    margin-bottom: 120px;
    /* margin-bottom: 75%; */
}

.wizard-buttons {
    width: 100%;
    height: 74px;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-evenly;
    position: fixed;
    bottom: 0;
}

    .foodprint-back {
        margin-top: 15px;
        font-family: Quicksand;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        color: white;
        border: 1px dashed #FFFFFF;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        cursor: pointer;
        padding: 0px 30px 0px 30px;
        height: 44px;
    }

        .foodprint-back-background1 {
            background: #219653;
        }

        .foodprint-back-background2 {
            background: #AC3E3E;
        }

        .foodprint-back-background3 {
            background: #DE7E29;
        }

        .foodprint-back-background4 {
            background: #2271DA;
        }

    .foodprint-next {
        margin-top: 15px;
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        color: #444444;
        background: #FFFFFF;
        border: 1px dashed #27AE60;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        cursor: pointer;
        padding: 0px 30px 0px 30px;
        height: 44px;
    }


.foodprint-title {
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    line-height: 55px;
}

.foodprint-support {
    margin-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
}

.foodprint-start {
    width: 177px;
    height: 64px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #444444;
    background: #FFFFFF;
    border: 1px dashed #BB6BD9;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-top: 30px;
    margin-bottom: 230px;
    cursor: pointer;
}

.step0-background-padding {
    padding-bottom: 30%;
}
.step2-background-heightresponsive {
    padding-bottom: 10px;
}
.step3-background-heightresponsive {
    padding-bottom: 15%;
}

@media screen and (max-height: 900px) {  

    .step0-background-padding {
        padding-bottom: 15%;
    }
    .step2-background-heightresponsive {
        padding-bottom: 0px;
    }
    .step3-background-heightresponsive {
        padding-bottom: 0px;
    }

}

@media screen and (max-height: 770px) {  

    .step0-background-padding {
        padding-bottom: 5%;
    }

}


@media screen and (max-height: 600px) {  

    .step0-background-padding {
        padding-bottom: 0%;
    }

}


@media only screen and (max-width: 966px) {

    .foodprint-step0-alignment {
        padding-top: 120px;
    }

    .foodprint-start {
        margin-bottom: 0px;
    }

    .foodprint-image {
        margin: 0px 30px 0px 30px;
    }
  
  }

@media only screen and (max-width: 600px) {

    .foodprint-step0-text {
        margin: 0px 40px 0px 40px;
    }

    .foodprint-image {
        margin: 0px;
        width: 100%;
        height: auto;
    }

    .foodprint-title {
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 45px;
    }
    
    .foodprint-support {
        margin-top: 10px;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
    }    

    .foodprint-intro {
        width: 90%;
    }

    .foodprint-calltoaction {
        width: auto;
    }

    .foodprint-calltoaction {
        font-size: 20px;
        line-height: 30px;
    }

    .wizard-buttons {
        height: 84px;
    }

    .foodprint-back {
        font-size: 16px;
    }

    .foodprint-next {
        font-size: 16px;
    }

}