@import url(https://fonts.googleapis.com/css?family=Open+Sans:bold,normal);
@import url(https://fonts.googleapis.com/css?family=Quicksand:bold,normal);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap'); */

.red-home-background {
  background: #EB5757;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  color: white;
}

  .red-home-text {
    font-family: 'Quicksand';
    font-weight: 800;
    margin: 70px 20px 20px 20px;
    text-align: center;
    /* width: 70%;
    max-width: 900px; */
  }

    .red-home-title {
      font-size: 44px;
      line-height: 55px;
    }

    .red-home-support {
      font-size: 20px;
      line-height: 25px;
      font-weight: normal;
    }

.header {
  display: flex;
  justify-content: center;
  color: #4F4F4F;
  margin-bottom: 30px;
}

.corona {
  width: 60%;
  max-width: 400px;
  margin-bottom: 100px;
}

.current{
  text-shadow: 0 0 .65px #333, 0 0 .65px #333;
}

.hide {
  display: none;
}

body {
  background-color:#FFFFFF;
  display: flex;
  flex-direction: column;
  color: black;
  font-family: 'Quicksand';
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

  .page-title {
    font-family: 'Quicksand';
    font-weight: 800;
    font-size: 60px;
    line-height: 82px;
    color: #333333;
    /* margin: 80px 20px 20px 20px; */
    margin: 80px 80px 20px;
  }

  .cover-title {
    margin-top: 40px;
    color: white;
  }

  .section-title {
    font-family: 'Quicksand';
    font-weight: bold;
    font-size: 36px;
    line-height: 24px;
  }

.impact-button {
  cursor: pointer;
  font-family: 'Quicksand';
  font-weight: bold;
  color: white;
  border: 1px dotted #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  font-family: 'Quicksand';
  outline: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  background: #BB6BD9;
  /* border-radius: 8px; */
  border-radius: 4px;
  padding: 5px 10px 5px 10px;
}

  .impact-button-impactpage {
    /* color: white; */
    background: #9B51E0;
    /* border: 1px dotted #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 5px 10px 5px 10px; */
  }

  .unit-text {
    display: inline;
  }

button:hover {
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

a:link, a:visited, a:active {
  text-decoration: none;
  color: #4F4F4F;
  font-weight: normal;
}

a:hover {
  /* text-decoration: underline; */
  color: #4F4F4F;
}

.beefvstofu {
  width: 60%;
  max-width: 820px;
  margin-top: 100px;
}

.foodcomparisondesktop {
  width: 90%;
  max-width: 1200px;
  margin-bottom: 30px;
}

.beefvstofumobile {
  display: none;
}

.home-note {
  margin: 30px 20px 120px 20px;
  font-family: Quicksand;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.foodprint-note {
  margin: 30px 20px 120px 20px;
  font-family: Quicksand;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  width: 80%;
  max-width: 1200px;
}

  .note-bold {
    display: inline;
    font-weight: bold;
  }

  .note-content {
    display: inline;
  }

/* comparisons */

/* .pollution {
  margin: 20px 0px 0px 0px;
} */

.impact-image {
  margin-top: 20px;
  width: 100%;
  max-width: 239px;
}

.same-as {
  font-size: 14px;
  line-height: 21px;
  margin: 20px 0px 10px 0px;
}

.comparison-section {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
}
  
  .comparison-element {
    display: flex;
    align-items: center;
    max-width: 239px;
    margin-top: 15px;
  }

    .comparison-image {
      height: 50px;
    }

    .comparison-text {
      margin-left: 10px;
    }

      .comparison {
        font-family: 'Quicksand';
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
      }

      .comparison-support {
        font-size: 14px;
        line-height: 21px;
        color: #828282;
      }

@media only screen and (max-width: 1300px) {

  .foodprint-note {
    width: 90%;
  }
  
}

@media only screen and (max-width: 1000px) {

  .header {
    color: #4F4F4F;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    text-align: center;
  }

  .nav {
    color: #4F4F4F;
    margin-top: 70px;
  }

  .beefvstofu {
    width: 80%;
  }

  .red-home-text {
    width: 80%;
  }

}

@media only screen and (max-width: 768px) {

  .impact-image {
    display: none;
  }

  .comparison-element {
    width: 100%;
    max-width: none;
  }

  .beefvstofu {
    display: none;
  }

  .foodcomparisondesktop {
    display: none;
  }

  .beefvstofumobile {
    display: block;
    display: initial;
    width: 90%;
    margin-top: 100px;
  }

  .red-home-title {
    font-size: 32px;
    line-height: 40px;
  }

  .red-home-support {
    font-size: 20px;
    line-height: 25px;
  }

}


@media only screen and (max-width: 630px) {

  .page-title {
    font-size: 48px;
    line-height: 60px;
    width: auto;
  }

  .section-title {
    font-size: 25px;
  }

}


@media only screen and (max-width: 460px) {

  .page-title {
    font-size: 35px;
    line-height: 50px;
    margin: 80px 20px 20px 20px;
  }

  .section-title {
    font-size: 25px;
    line-height: 35px;
  }
  
}


@media only screen and (max-width: 350px) {

  .page-title {
    font-size: 32px;
    line-height: 45px;
  }

}
.pink-cover-background {
    background: #BB6BD9;
    width: 100%;
  }

    .mouth-foodprint-button {
        background: #FFFFFF;
        border: 1px dashed #BB6BD9;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #9B51E0;
        padding: 5px 20px 5px 20px;
        cursor: pointer;
    }

    .mouth {
        width: 100%;
        float: right;
        margin-top: -130px;
        margin-bottom: 50px;
    }

    .grey-foodselection-background {
        background-color: #F2F2F2;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

        .cover-support-black {
            color: #444444;
            font-family: 'Quicksand';
            font-weight: normal;
            font-size: 26px;
            line-height: 35px;
        }

    .home-fooddetails-center {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* margin-left: 30px; */
    }

@media only screen and (max-width: 1000px) { 
    
    .mouth {
        margin-top: 0px;
    }

    .home-fooddetails-center {
        margin-left: 0px;
    }
    
}

.menu-impact-button {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
}

.menu {
    display: flex;
    flex-direction: row;
    /* justify-content: flex-end; */
    align-items: center;
    position: absolute;
    top: 20px;
    right: 20px;
    /* box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 8px 8px;
    background-color: #FFFFFF;
    width: 900px; */
}

.logo {
    /* display: flex;
    justify-content: flex-start;
    padding: 10px;
    margin-right: 200px; */
    position: absolute;
    top: 10px;
    left: 20px;
  }

    .logoicon {
      height: 44px;
      width: 44px;
      margin-right: 10px;
    }

.white {
    color: white;
}

.desktop {
    display: flex;
    font-size: 14px;
    margin-right: 25px;
    list-style-type: none;
}

.hamburger, .mobile {
    display: none;
}

@media only screen and (max-width: 1000px) {

    .mobile {
        display: block;
    }

    .menu {
        display: flex;
        justify-content: flex-start;
        background-color: transparent;
        box-shadow: none;
        width: 100%;
    }

    .desktop {
        display: none;
    }

    .hamburger {
        display: block;
        float: right;
        cursor: pointer;
        position: absolute;
        top: 1.1em;
        right: 0;
        margin-right: 1em;
    }

    .logo {
        margin-right: 0px;
    }

}


@media only screen and (max-width: 400px) {

    .logo {
        /* padding: 5px; */
        margin-right: 120px;
    }

}
.nav {
    font-family: 'Quicksand';
    font-size: 14px;
    line-height: 40px;
  }

/* .impact-button-impacttform {
    margin-left: 30px;
}  */
.form-3level {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 25px;
  justify-content: space-around;
}
  
.impact-button-homepage-form {
    width: 100%;
}

.form-home {
    max-width: 840px;
    margin-right: 20px;
    margin-left: 20px;
    /* background: #BB6BD9; */
    background-color: #F2F2F2;
    border-radius: 20px;
    padding: 20px;
}
  
.form-impact {
    margin-right: 20px;
    margin-left: 20px;
    background: #F2F2F2;
    border-radius: 20px;
    padding: 10px;
}
  
.form-title {
    padding-top: 30px;
    padding-bottom: 10px;
}

.form-support {
    font-family: 'Quicksand';
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    padding-top: 10px;
    padding-bottom: 30px;
}

/* .form-input-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
} */

    .form-input-section-impactpage {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: column;
    }

    .form-amountandunit {
      /* border: 3px dotted #BDBDBD; */
      border-radius: 8px;
      background-color: #E0E0E0;
    }

    .form-input {
        font-family: 'Quicksand';
        font-weight: bold;
        border: 1px dotted #BDBDBD;
        border-radius: 8px;
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: normal;
        background-color: #FFFFFF;
        font-size: 24px;
        line-height: 40px;
        padding-top: 10px;
        padding-bottom: 10px;
        /* margin-bottom: 15px; */
        cursor: pointer;
    }
  
      input:focus, select:focus {
        border-color: #BB6BD9;
        outline: none;
      }
    
    .form-type {
      width: 830px;
      margin: 10px;
      -moz-appearance:none; /* Firefox */
      -webkit-appearance:none; /* Safari and Chrome */
      background: url(/static/media/arrow.a652c61c.svg) no-repeat right white;
      appearance: none;
      padding: 10px;
      text-align-last: center;
    }
  
    .form-number {
      width: 260px;
      margin-right: 5px;
      text-align: right;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 8px 0px 0px 8px;
    }
  
    .form-select {
      margin: 0px 25px 0px 25px;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      /* background-color: #F2F2F2; */
      /* color: #4F4F4F; */
      font-family: 'Quicksand';
      font-weight: bold;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 40px;
      padding-top: 10px;
      padding-bottom: 10px;
      /* margin-bottom: 15px; */
    }
  
    .form-button {
      font-size: 24px;
      line-height: 32px;
      padding: 15px 78px 15px 78px;
      margin-bottom: 20px;
    }
    
    /* .form-button-position-impact {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 20px;
      margin-bottom: 25px;
      justify-content: space-around;
    } */

    .form-button-old-calculation {
      color: #4F4F4F;
      background: #F2F2F2;
      border: 1px dotted #BDBDBD;
    }

    .form-button-new-calculation {
      color: white;
      background: #BB6BD9;
      border: 1px dotted #FFFFFF;
    }
  
  .form-image {
    width: 100%;
    height: 120px;
  }


  @media only screen and (max-width: 1000px) {

    .form-home {
        margin: 0px;
    }

  }

  @media only screen and (max-width: 900px) {

    .form-type {
      max-width: 750px;
    }

    .form-number {
      width: 200px;
    }

    .impact-button-impactpage-form {
      width: 400px;
      font-size: 22px;
    }

}

@media only screen and (max-width: 820px) {

  .form-type {
    max-width: 630px;
  }

  .form-number {
    width: 110px;
  }

  .impact-button-impactpage-form {
    width: 350px;
    font-size: 19px;
  }

}

@media only screen and (max-width: 700px) {

.form-3level {
  display: block;
}

  .form-type {
    width: 100%;
    margin: 0px;
  }

  .form-number {
    width: 50%;
  }

  .form-select {
    margin: 0px 25px 0px 25px;
  }

  .impact-button-impactpage-form {
    width: 100%;
    margin-top: 20px;
    font-size: 19px;
  }

  .form-button {
    padding: 15px 20px 15px 20px;
  }

}

@media only screen and (max-width: 430px) {
  
  .form-number {
    width: 40%;
  }

}

@media only screen and (max-width: 400px) {
    
  .form-input {
    font-size: 16px;
  }

  .form-select {
    font-size: 16px;
  }

}


@media only screen and (max-width: 367px) {
  
  .form-number {
    width: 29%;
  }

}







/* @media only screen and (max-width: 460px) {

    .form-impact {
        margin-right: 10px;
        margin-left: 10px;
      }
    
    .form-input {
        border: 3px dotted #BDBDBD;
        border-radius: 8px;
        font-size: 16px;
        margin: 0px;
        margin-bottom: 10px;
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .form-number {
        margin-right: 5px;
        text-align: center;
        padding-left: 4px;
    }

    .form-select {
        text-align: center;
        font-size: 16px;
    }

    .form-button {
        font-size: 15px;
        width: 100%;
        margin: 0px 0px 20px 0px;
        padding: 15px;
    }

    .form-button-position-impact {
        flex-direction: column;
    }

} */
.categories {
    display: flex;
}

    .twocategories-home {
        display: flex;
    }

    .twocategories-impact {
        display: inline;  
    }

    .category {
        color: white;
        border-radius: 10px;
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        text-align: center;
        cursor: pointer;
        border: 1px dotted white;
    }

        button {
            outline: none; 
            border: none;
        }

    .animal {
        background-color: #EB5757;
    }

    .fruits {
        background-color: #27AE60;
    }

    .grains {
        background-color: #F2994A;
    }

    .other-food {
        background-color: #2D9CDB;
    }

    .opacit {
        -webkit-filter: saturate(0);
                filter: saturate(0);
        opacity: 0.6;
    }

    .category-titleandsupport-home {
        margin-left: 10px;
    }

    .category-home {
        width: 400px;
        height: 250px;
        margin: 10px;
    }

    .category-impact {
        width: 200px;
        height: 180px;
        margin: 5px;
        vertical-align: top;
    }

    .category-image-home {
        height: 130px;
        margin: 10px;
    }

    .category-image-impact {
        height: 80px;
        margin: 5px;
        padding: 8px;
    }

    @media only screen and (max-width: 900px) {

        .category-impact {
            width: 180px;
        }
    
    }

    @media only screen and (max-width: 820px) {

        .category-impact {
            width: 150px;
        }
    
    }

    @media only screen and (max-width: 700px) {

        .category-impact {
            width: 130px;
            height: 150px;
        }

        .category-image-impact {
            height: 60px;
            padding: 4px;
        }

    }

    @media only screen and (max-width: 620px) {

        .category-impact {
            width: 100px;
            height: 130px;
            font-size: 15px;
        }

        .category-image-impact {
            height: 40px;
            padding: 0px;
        }
    
    }

    @media only screen and (max-width: 500px) {

        .category-impact {
            width: 89px;
            margin: 3px;
            height: 120px;
            font-size: 12px;
        }
    
    }

    @media only screen and (max-width: 440px) {

        .category-impact {
            width: 80px;
            height: 110px;
            font-size: 11px;
            margin: 2px;
        }

        .category-image-impact {
            height: 30px;
        }
    
    }

    @media only screen and (max-width: 400px) {

        .category-impact {
            width: 70px;
            height: 100px;
            font-size: 10px;
        }

    
    }

    @media only screen and (max-width: 356px) {

        .category-impact {
            width: 65px;
            height: 100px;
            font-size: 9px;
        }

        .category-image-impact {
            height: 25px;
        }

    
    }

    @media only screen and (max-width: 336px) {

        .category-impact {
            width: 60px;
            height: 100px;
            font-size: 8px;
        }

    
    }





    @media only screen and (max-width: 880px) {

        .category-home {
            width: 350px;
        }
    
    }

    @media only screen and (max-width: 780px) {

        .category-home {
            width: 300px;
        }
    
    }

    @media only screen and (max-width: 680px) {

        .category-home {
            width: 250px;
            height: 150px;
            margin: 5px;
        }

        .category-image-home {
            height: 80px;
            margin: 5px;
        }

        .form-support {
            font-size: 14px;
            line-height: 20px;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        .category-titleandsupport-home {
            margin-left: 5px;
        }
    
    }

    @media only screen and (max-width: 560px) {

        .category-home {
            width: 200px;
            font-size: 15px;
            /* line-height: 24px; */
        }
    
    }

    @media only screen and (max-width: 460px) {

        .category-home {
            width: 180px;
        }
    
    }

    @media only screen and (max-width: 420px) {

        .category-home {
            width: 170px;
        }
    
    }

    @media only screen and (max-width: 400px) {

        .category-home {
            width: 160px;
        }
    
    }

    @media only screen and (max-width: 378px) {

        .category-home {
            width: 150px;
        }

        .category-image-home {
            height: 60px;
        }
    
    }

    @media only screen and (max-width: 368px) {

        .category-home {
            width: 140px;
        }
    
    }


    @media only screen and (max-width: 340px) {

        .category-home {
            width: 130px;
        }
    
    }

    @media only screen and (max-width: 328px) {

        .category-home {
            width: 120px;
        }

        
    
    }
.fb-share-button {
    font-family: 'Quicksand';
    font-weight: bold;
    font-size: 14px;
    line-height: 40px;
    margin-top: 200px;
    color: white;
    background-color: #2F80ED;
    border: 1px dotted #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 280px;
  }
  .fb-share-button-foodprint {
    font-family: 'Quicksand';
    font-weight: bold;
    font-size: 14px;
    line-height: 40px;
    color: white;
    background-color: #2F80ED;
    border: 1px dotted #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 280px;
  }
  
    .fb-share-text {
      color: white;
      font-weight: bold;
      text-align: center;
    }
.cover {
    background-color: #2FE2ED;
  }
  
  .cover-image {
    width: 100%;
    margin-top: 50px;
    margin-bottom: -20px;
  }
  
      .cover-support {
        font-family: 'Quicksand';
        font-weight: normal;
        font-size: 26px;
        line-height: 35px;
        /* color: black; */
        color: white;
      }

      .cover-button {
        margin-top: -20px;
      }
  
      .cover-impact-button {
        font-size: 14px;
        line-height: 24px;
        padding: 3px 40px 3px 40px;
      }

@media only screen and (max-width: 630px) {

  .cover-button {
    margin-top: 5px;
  }

}
.footer {
    margin-top: 50px;
    padding: 2em;
    text-align: center;
  }
  
    .findUs {
      font-family: 'Quicksand';
      font-weight: normal;
      font-size: 14px;
      line-height: 40px;
    }
  
    .social-section {
      display: flex;
      justify-content: center;
      margin-bottom: 50px;
    }
  
      .social {
        margin: 15px;
        text-align: center;
      }

        .fc {
          margin-left: 0px;
        }

        .insta {
          margin-right: 0px;
        }
  
  .rights {
    font-family: 'Quicksand';
    font-weight: 300;
    font-size: 15px;
    line-height: 40px;
    color: #4F4F4F;
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .modal-button {
    padding: 10px 20px 10px 20px;
    margin-top: 20px;
    font-size: 14px;
  }

@media only screen and (max-width: 460px) {

    .rights {
        font-size: 12px;
    }

    .social {
      margin: 7px;
      text-align: center;
    }

}
.impact-cover {
    width: 100%;
    background-color: #F2F2F2;
}

.default-impact-image {
    width: 90%;
    max-width: 800px;
    margin-top: 150px;
    margin-bottom: 30px;
}

.solution-cover {
    margin-top: 100px;
    width: 100%;
    background-color: #BB6BD9;
}

.corona-impact {
    margin-left: 50%;
    width: 300px;
}

@media only screen and (max-width: 821px) {
    .corona-impact {
        margin-left: 40%;
    }
}

@media only screen and (max-width: 620px) {
    .corona-impact {
        margin-left: 20%;
    }
}

@media only screen and (max-width: 440px) {
    .corona-impact {
        margin-left: 20%;
        width: 240px;
    }
}

@media only screen and (max-width: 360px) {
    .corona-impact {
        margin-left: 20%;
        width: 200px;
    }
}


.pollutions {
    margin: 80px 20px 0px 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
  
.pollutions-animation {
    -webkit-animation: moveFromBottom 0.75s ease-in-out;
            animation: moveFromBottom 0.75s ease-in-out;
}

.pollution-grams {
    padding: 30px;
}

.pollution-impact {
    background: #FAFAFA;
    padding: 30px 30px 15px 30px;
}

.pollution-border-top {
    border-top: 1px solid  #E0E0E0;
}

.pollution-border-right {
    border-right: 1px solid  #E0E0E0;
}

.pollution-corner-bottomleft {
    border-radius: 0px 0px 0px 10px;
}

.pollution-corner-bottomright {
    border-radius: 0px 0px 10px 0px;
}

.foodprint-sameas {
    text-align: left;
    padding: 10px 40px 20px 40px;
    background: #FAFAFA;
    border-radius: 0px 0px 10px 10px;
}

@-webkit-keyframes moveFromBottom {
    0% { -webkit-transform: translateY(2000px); transform: translateY(2000px); opacity: 0%; }
    80% { -webkit-transform: translateY(-10px); transform: translateY(-10px); }
    100% { -webkit-transform: translateY(0px); transform: translateY(0px); opacity: 100%; }
}

@keyframes moveFromBottom {
    0% { -webkit-transform: translateY(2000px); transform: translateY(2000px); opacity: 0%; }
    80% { -webkit-transform: translateY(-10px); transform: translateY(-10px); }
    100% { -webkit-transform: translateY(0px); transform: translateY(0px); opacity: 100%; }
}

.pollution-card {
    display: flex;
}

.pollution-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
}

.impact-description {
    font-size: 16px;
    line-height: 10px;
    margin-top: 10px;
}

@media only screen and (max-width: 768px) {

    .pollution-card {
        display: inline;
    }

}
.grams {
  display: flex;
  justify-content: space-between;
}

/* .grams-amount {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
} */

.grams-amount-foodprint {
  display: flex;
}

.nutritional-values {
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-evenly;
  width: 40%;
}

  .nutritional-value {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

@media only screen and (max-width: 900px) {

  .grams {
      display: inline;
      border-bottom: none;
  }

  .nutritional-values {
    justify-content: space-evenly;
    width: 100%;
    margin-top: 20px;
  }
    
}

@media only screen and (max-width: 400px) {

  .nutritional-values {
    font-size: 14px;
  }
    
}
.solution {
    font-family: Quicksand;
    color: #FFFFFF;
    margin-top: 50px;
    margin-bottom: 150px;
  }

  .solution-title-section {
    width: 900px;
  }

  .solution-title {
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 50px;
    display: inline;
  }

    .solution-title-bold {
      font-weight: bold;
    }

    .uppercase {
      text-transform: uppercase;
    }

  .alternatives {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .alternative-recommended{
    background-color: #6FCF97;
    border-radius: 15px;
    padding: 5px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #219653;
    margin-top: 40px;
  }
    .alternative-recommended-foodprint{
      background-color: #6FCF97;
      border-radius: 15px;
      padding: 5px;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #219653;
      margin-top: 20px;
    }

    .alternative-recommended-text {
        padding: 5px;
    }

    .alternative {
        background-color: #8C3BD9;
        border-radius: 10px;
        color: #FFFFFF;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        width: 900px;
    }
      .alternative-foodprint {
        background-color: #8C3BD9;
        border-radius: 10px;
        color: #FFFFFF;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        width: 600px;
        display: flex;
        align-items: center;
        padding: 10px;
      }

    .alternative-items {
        display: flex;
        font-size: 36px;
        line-height: 50px;
    }
      .alternative-items-foodprint {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 18px;
        line-height: 35px;
      }

    .alternative-amount {
      font-weight: normal;
      display: inline;
    }

    .alternative-not-rec {
        margin-top: 40px;
    }
      .alternative-not-rec-foodprint {
        margin-top: 20px;
      }

    .alternative-grams{
      background-color: #9B51E0;
      font-style: normal;
      font-weight: normal;
      border-radius: 10px 10px 0px 0px;
      padding: 30px;
    }
      .alternative-grams-foodprint {
        background-color: none;
        font-style: normal;
        font-weight: normal;
        border-radius: 10px 10px 0px 0px;
        /* padding: 20px; */
        padding: 20px 0px 20px 0px;
        display: flex;
        justify-content: center;
        width: 40%;
      }
        .alternative-grams-foodprint-nutr {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
          .nutrvalue-foodprint-flex {
            display: flex;
          }
            .nutrvalue-foodprint {
              font-size: 16px;
              margin-top: -10px;
            }

        .alternative-impact {
            font-size: 23px;
            line-height: 29px;
            font-size: 16px;
            padding: 30px 30px 15px 30px;
        }

        .warning {
          color: #F2C94C;
        }

        .same-impact {
          font-style: normal;
          font-weight: normal;
        }

        .solution-calculation {
          font-family: "QuickSand";
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          background-color: transparent;
          border-color: transparent;
          color: #FFFFFF;
          padding-left: 30px;
          padding-bottom: 30px;
        }

        .solution-calculation:hover {
          box-shadow: none;
          text-decoration: underline;
          cursor: pointer;
        }

@media only screen and (max-width: 1100px) {

  .alternative-foodprint {
    width: 760px;
  }

  .alternative-grams-foodprint {
    width: 50%;
  }

}
        

@media only screen and (max-width: 1000px) {
  
  .solution-title-section {
    width: 750px;
  }
  
  .alternative {
    width: 750px;
  }

  .alternative-foodprint {
    width: 600px;
  }
  .alternative-grams-foodprint {
    width: 40%;
  }


}


@media only screen and (max-width: 900px) {

  .alternative-items {
    justify-content: center;
  }

}

@media only screen and (max-width: 800px) {

  .solution-title-section {
    width: 620px;
  }
  
  .solution-title {
    font-size: 30px;
    line-height: 40px;
  }
  
  .alternative {
    width: 600px;
    font-size: 28px;
  }

  .alternatives {
    font-size: 25px;
    margin-top: 30px;
  }

  .alternative-foodprint {
    flex-direction: column;
    width: 400px;
    padding-bottom: 20px;
  }
  .alternative-grams-foodprint {
    padding-bottom: 0px;
    width: auto;
  }
  
}

@media only screen and (max-width: 700px) {

  .solution-title-section {
    width: 550px;
  }

  .solution-title {
    font-size: 28px;
    line-height: 35px;
  }
  
  .alternative {
    width: 550px;
    font-size: 25px;
  }

  .alternatives {
    font-size: 22px;
    margin-top: 20px;
    line-height: 30px;
  }
  
}

@media only screen and (max-width: 630px) {

  .solution-title-section {
    width: 500px;
  }

  .solution-title {
    font-size: 25px;
    line-height: 35px;
  }
  
  .alternative {
    width: 500px;
    font-size: 23px;
  }

  .alternatives {
    font-size: 20px;
    margin-top: 20px;
    line-height: 30px;
  }
  
}

@media only screen and (max-width: 600px) {

  .solution-title-section {
    width: 450px;
  }

  .solution-title {
    font-size: 23px;
    line-height: 35px;
  }

  .alternative-items {
    font-size: 23px;
    line-height: 35px;
  }
  
  .alternative {
    width: 450px;
    font-size: 23px;
  }

  .alternatives {
    font-size: 20px;
    margin-top: 20px;
    line-height: 30px;
  }

  .alternative-foodprint {
    width: 350px;
  }
  
}

@media only screen and (max-width: 500px) {

  .solution-title-section {
    width: 400px;
  }

  .solution-title {
    font-size: 22px;
    line-height: 35px;
  }
  
  .alternative {
    width: 400px;
    font-size: 23px;
  }

  .alternatives {
    font-size: 20px;
    margin-top: 20px;
    line-height: 30px;
  }
  
}

@media only screen and (max-width: 450px) {

  .solution-title-section {
    width: 350px;
  }

  .solution-title {
    font-size: 26px;
    line-height: 35px;
  }

  .alternative-items {
    font-size: 20px;
    line-height: 35px;
  }
  
  .alternative {
    width: 350px;
    font-size: 23px;
  }

  .alternatives {
    font-size: 20px;
    margin-top: 20px;
    line-height: 30px;
  }

  .alternative-foodprint {
    width: 330px;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }

}

@media only screen and (max-width: 400px) {

  .solution-title-section {
    width: 300px;
  }

  .solution-title {
    font-size: 23px;
    line-height: 35px;
  }

  .alternative-items {
    font-size: 14px;
    line-height: 35px;
  }
  
  .alternative {
    width: 290px;
    font-size: 23px;
  }

  .alternatives {
    font-size: 20px;
    margin-top: 20px;
    line-height: 30px;
  }

  .alternative-foodprint {
    width: 310px;
  }
  
  
}

@media only screen and (max-width: 375px) {

  .solution-title-section {
    width: 290px;
  }

  .solution-title {
    font-size: 21px;
    line-height: 35px;
  }
  
  .alternative {
    width: 290px;
    font-size: 20px;
  }

  .alternatives {
    font-size: 20px;
    margin-top: 20px;
    line-height: 30px;
  }

  .alternative-impact {
    font-size: 14px;
    margin-top: 10px;
  }

  .alternative-foodprint {
    width: 280px;
  }
  
}

@media only screen and (max-width: 345px) {

  .solution-title {
    font-size: 21px;
    line-height: 35px;
  }
  
  .alternative {
    font-size: 16px;
  }

  .alternatives {
    font-size: 20px;
    margin-top: 20px;
    line-height: 30px;
  }

  .alternative-recommended{
    font-size: 13px;
  }

  .alternative-impact {
    font-size: 12px;
  }

  .alternative-foodprint {
    width: 265px;
  }
  
}
.icon-food {
    display: flex;
}

.icon-food-image-foodprint {
    font-size: 50px;
    margin: 20px 10px 10px 10px;
}


.science-background {
    background: #F2F2F2;
    width: 100%;
}

.science-section-title {
    font-size: 25px;
    line-height: 40px;
    color: #333333;
    margin: 30px 20px 0px 20px;
    width: 900px;   
}

.science-article {
    background: white;
    width: 840px;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    cursor: pointer;
    border: 1px dotted white;
}

    .science-article:hover {
        border: 1px dotted #BB6BD9;
    }

    .science-article-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
    }

    .science-article-support { 
        font-style: italic;
        font-size: 14px;
        line-height: 24px;
        color: #333333;
    }

    .science-article-date {
        font-style: italic;
        font-size: 14px;
        line-height: 24px;
        color: #828282;
    }


@media only screen and (max-width: 1000px) {
    
    .science-section-title {
        width: 800px;
    }
    
    .science-article {
        width: 800px;
    }
      
}

@media only screen and (max-width: 877px) {
    
    .science-section-title {
        width: 700px;
    }
    
    .science-article {
        width: 700px;
    }
}

@media only screen and (max-width: 790px) {

    .science-section-title {
        width: 550px;
    }
    
    .science-article {
        width: 550px;
    }

}

@media only screen and (max-width: 630px) {

    .science-page {
        align-items: flex-start;
    }

    .science-section-title {
        width: auto;
        font-size: 25px;
    }

    .science-article {
        width: auto;
    }

}

@media only screen and (max-width: 460px) {

    .science-section-title {
        font-size: 18px;
    }
    
}
.about-background {
    background: #F3ECE1;
    width: 100%;
  }
  
  .about-alignment {
    display: flex;
    padding-top: 160px;
    justify-content: center;
    flex-wrap: wrap;
  }
  
    .napoleon-image {
      height: 330px;
      margin: 40px;
    }
  
    .about-vision {
      display: flex;
      flex-direction: column;
      width: 430px;
      margin: 40px;
    }
  
      .vision {
        font-family: 'Quicksand';
        font-weight: bold;
        font-size: 30px;
        line-height: 45px;
        margin-bottom: 10px;
      }
  
      .vision-support {
        font-size: 16px;
        line-height: 35px;
      }
  
  .team-title {
    font-size: 25px;
    line-height: 40px;
    text-align: center;
    margin-top: 130px;
  }
  
  .team-alignment {
    display: flex;
    margin: 20px 20px 100px 20px;
    justify-content: center;
    flex-wrap: wrap;
  }
  
    .team-image {
      height: 180px;
      margin: 30px 50px 20px 50px;
    }
  
    .team-member {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      margin-bottom: 5px;
    }
  
    .team-role {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      margin-bottom: 20px;
    }

    

@media only screen and (max-width: 877px) {
    
    .about-alignment {
        padding-top: 70px;
    }

    .napoleon-image {
      margin-top: 100px;
    }
    
}

@media only screen and (max-width: 460px) {
  
    .napoleon-image {
      height: 250px;
      margin-top: 70px;
    }

    .about-vision {
        width: 300px;
        margin: 20px;
    }

        .vision {
            font-size: 25px;
            line-height: 40px;
            margin-bottom: 10px;
        }
    
}
.foodprint {
    font-family: Quicksand;
    color: #FFFFFF;
    text-align: left;
}

.step0-background {
    background-color: #9B51E0;
    width: 100%;
}

.step1-background {
    background-color: #27AE60;
    width: 100%;
}

.step2-background {
    background-color: #C64F4F;
    width: 100%;
}

.step3-background {
    background-color: #F2994A;
    width: 100%;
}

.step4-background {
    background-color: #2F80ED;
    width: 100%;
}

.foodprint-step0-alignment {
    display: flex;
    padding-top: 200px;
    justify-content: center;
    flex-wrap: wrap;
}

    .foodprint-step0-text {
        display: flex;
        flex-direction: column;
        width: 430px;
    }

    .foodprint-image {
        height: 376px;
        margin-left: 40px;
      }

.foodprint-alignment {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

.foodprint-intro {
    margin-top: 30px;
}

    .foodprint-intro-title {
        font-family: Quicksand;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
    }

    .progress-overview {
        display: flex;
    }

        .progress-box {
            width: 122px;
            height: 5px;
            background: rgba(255, 255, 255, 0.25);
            border-radius: 10px;
            margin: 10px 5px 0px 0px;
        }

        .progress-box-active {
            background-color: white;
        }
    
    .step-name {
        margin-top: 10px;
        font-family: Quicksand;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        display: flex;
    }

        .step-name-bold {
            font-weight: bold;
        }

    .step-image {
        margin-top: 50px;
        height: 100px;
    }

    .foodprint-calltoaction {
        margin-top: 20px;
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        width: 500px;
    }

.FoodprintForms {
    margin-bottom: 120px;
    /* margin-bottom: 75%; */
}

.wizard-buttons {
    width: 100%;
    height: 74px;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-evenly;
    position: fixed;
    bottom: 0;
}

    .foodprint-back {
        margin-top: 15px;
        font-family: Quicksand;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        color: white;
        border: 1px dashed #FFFFFF;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        cursor: pointer;
        padding: 0px 30px 0px 30px;
        height: 44px;
    }

        .foodprint-back-background1 {
            background: #219653;
        }

        .foodprint-back-background2 {
            background: #AC3E3E;
        }

        .foodprint-back-background3 {
            background: #DE7E29;
        }

        .foodprint-back-background4 {
            background: #2271DA;
        }

    .foodprint-next {
        margin-top: 15px;
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        color: #444444;
        background: #FFFFFF;
        border: 1px dashed #27AE60;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        cursor: pointer;
        padding: 0px 30px 0px 30px;
        height: 44px;
    }


.foodprint-title {
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    line-height: 55px;
}

.foodprint-support {
    margin-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
}

.foodprint-start {
    width: 177px;
    height: 64px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #444444;
    background: #FFFFFF;
    border: 1px dashed #BB6BD9;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-top: 30px;
    margin-bottom: 230px;
    cursor: pointer;
}

.step0-background-padding {
    padding-bottom: 30%;
}
.step2-background-heightresponsive {
    padding-bottom: 10px;
}
.step3-background-heightresponsive {
    padding-bottom: 15%;
}

@media screen and (max-height: 900px) {  

    .step0-background-padding {
        padding-bottom: 15%;
    }
    .step2-background-heightresponsive {
        padding-bottom: 0px;
    }
    .step3-background-heightresponsive {
        padding-bottom: 0px;
    }

}

@media screen and (max-height: 770px) {  

    .step0-background-padding {
        padding-bottom: 5%;
    }

}


@media screen and (max-height: 600px) {  

    .step0-background-padding {
        padding-bottom: 0%;
    }

}


@media only screen and (max-width: 966px) {

    .foodprint-step0-alignment {
        padding-top: 120px;
    }

    .foodprint-start {
        margin-bottom: 0px;
    }

    .foodprint-image {
        margin: 0px 30px 0px 30px;
    }
  
  }

@media only screen and (max-width: 600px) {

    .foodprint-step0-text {
        margin: 0px 40px 0px 40px;
    }

    .foodprint-image {
        margin: 0px;
        width: 100%;
        height: auto;
    }

    .foodprint-title {
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 45px;
    }
    
    .foodprint-support {
        margin-top: 10px;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
    }    

    .foodprint-intro {
        width: 90%;
    }

    .foodprint-calltoaction {
        width: auto;
    }

    .foodprint-calltoaction {
        font-size: 20px;
        line-height: 30px;
    }

    .wizard-buttons {
        height: 84px;
    }

    .foodprint-back {
        font-size: 16px;
    }

    .foodprint-next {
        font-size: 16px;
    }

}
.foodprint-form {
    display: flex;
    margin-top: 20px;
}

    .foodprint-input {
        width: 58px;
        height: 46px;
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        text-transform: uppercase;
        color: #444444;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border: 0px;
        margin: 0px; 
    }

    /* removing the input number default increment buttons from Chrome */
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; 
      }
    /* removing the input number default increment buttons from Mozilla */
    input[type=number] { 
        -moz-appearance: textfield;
        -webkit-appearance: textfield;
                appearance: textfield;
        margin: 0; 
    }

    .foodprint-input-more {
        width: 48px;
        height: 48px;
        font-family: Quicksand;
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        color: #444444;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px 8px 8px 0px;
        cursor: pointer;
    }

    .foodprint-input-less {
        width: 48px;
        height: 48px;
        font-family: Quicksand;
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        color: #444444;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px 0px 0px 8px;
        cursor: pointer;
    }

        .foodprint-input-less-inactive {
            color: rgba(68, 68, 68, 0.25);
            cursor: default;
        }

        .foodprint-input-less-inactive:hover {
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          }
    
    .foodprint-measure {
        width: 20px;
        height: 48px;
        font-family: Quicksand;
        font-style: normal;
        font-size: 20px;
        line-height: 25px;
        text-align: left;
        color: #444444;
        background: #FFFFFF;
        box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.25);
        /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */
        border: 0px;
    }

        .foodprint-measure-text{
            margin-top: 12px;
        }

    .foodprint-foodlabel{
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        color: #FFFFFF;
        margin: 10px;
    }

@media only screen and (max-width: 600px) {

    .foodprint-input{
        width: 50px;
        font-size: 16px;
        line-height: 20px;
        border-radius: 0px 0px 0px 0px;
        margin: 0px;
        padding: 1px;
    }

    .foodprint-input-more {
        width: 40px;
        font-size: 16px;
        line-height: 20px;
    }

    .foodprint-input-less {
        width: 40px;
        font-size: 16px;
        line-height: 20px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    }
    
    .foodprint-measure{
        font-size: 16px;
        line-height: 20px;
    }

    .foodprint-measure-text{
        margin-top: 14px;
    }
    
    .foodprint-foodlabel{
        max-width: 110px;
        font-size: 16px;
        line-height: 20px;
        margin-top: 12px;
        margin-bottom: 0px;
    }

}
.foodprint-ready {
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 35px;
    margin-top: 50px;
}

.foodselected-and-editbutton {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    width: 80%;
    max-width: 1200px;
}

    .food-selected-title {
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 35px;
    }

    .edit-foodprint {
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 35px;
        color: #F2C94C;
        background-color: transparent;
        cursor: pointer;
    }

        .edit-foodprint:hover {
            box-shadow: none;
        }

.categories-selected {
    background: #8435CE;
    border: 1px solid #333333;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-top: 10px;
    width: 80%;
    max-width: 1200px;
}

.four-categories {
    display: flex;
    justify-content: space-evenly;
}

.category-selected {
    background-color: #BB6BD9;
    width: 100%;
    /* margin: 1px; */
    border-right: 1px solid #333333;
    border-bottom: 1px solid #333333;
    padding: 20px;
}

    .categories-selected-left {
        border-radius: 10px 0px 0px 0px;
    }

    .categories-selected-right {
        border-radius: 0px 10px 0px 0px;
        border-right: none;
    }

        .category-selected-title {
            font-family: Quicksand;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 5px;
        }

        .food-selected {
            display: flex;
            /* align-items: baseline; */
        }
    
    .foodprint-nutritionalvalues {
        padding: 20px;
        font-family: Quicksand;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        justify-content: center;
    }

        .foodprint-nutritionalvalues-title {
            font-weight: bold;
        }
        
.weeklyfoodprint-title {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    margin-top: 60px;
    width: 80%;
    max-width: 1200px;
}

.weeklyfoodprint-cards {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 80%;
    max-width: 1200px;
}

.weeklyfoodprint-impactcard {
    background: #FFFFFF;
    border: 1px solid #333333;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color:  #333333;
    text-align: center;
    width: 30%;
    margin-top: 10px;
}

    .impactcard {
        background: #FFECB3;
        border-radius: 10px 10px 0px 0px;
        padding: 20px;
    }

    .co2-color {
        background: #FFECB3;
    }

    .water-color {
        background: #B2EBF2;
    }

    .land-color {
        background: #DCEDC8;
    }

        .impactcard-title {
            font-family: Quicksand;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 40px;
        }

        .impactcard-subtitle {
            font-family: Quicksand;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
        }

    .topthree-section {
        margin: 20px 20px 50px 20px;
    }

    .topthree-title {
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #333333;
    }

        .polluting-impact-selection {
            display: flex;
            padding: 25px 20px 0px 20px;
        }

        .polluting-impact {
            margin: 0px 20px 0px 20px;
            background: #FAFAFA;
            border: 1px solid #666666;
            box-sizing: border-box;
            border-radius: 15px;
            width: 90%;
            height: 22px;
        }

            .pollution-impact-filling {
                height: 20px;
                border-radius: 15px;
                min-width: 7%;
                width: 7%;
            }

        .polluting-impact-values {
            display: flex;
            font-family: Quicksand;
            font-style: normal;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: #666666;
            justify-content: center;
            margin-top: 2px;
        }

        .polluting-impact-percentage {
            font-weight: bold;
        }

.weeklyfoodprint-support {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    width: 80%;
    max-width: 1200px;
    margin-top: 10px;
}

    .arrow-desktop {
        height: 60px;
        margin: 30px;
    }
    .arrow-mobile {
        height: 60px;
        display: none;
    }

    .foodprint-solution {
        margin-top: 30px;
        width: 77%;
        max-width: 1165px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px 20px 20px;
        background: #BB6BD9;
        border: 1px solid #333333;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
    }

    .alternatives-group-foodprint {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

        .foodprint-alternative-title-section {
            margin-left: 5%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

            .foodprint-alternative-titleandimage {
                display: flex;
                justify-content: center;
            }

                .foodprint-alternative-title {
                    font-family: Quicksand;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 28px;
                    line-height: 35px;
                    text-align: center;
                }
            
            .foodprint-alternative-optimisenutrvalue {
                display: flex;
            }

.foodprint-datasource-section {
    margin-top: 20px;
    width: 100%;
}


/* @media only screen and (max-width: 1350px) {

    .arrow-desktop {
        display: none;
    }
    .arrow-mobile {
        display: block;
    }

    .foodprint-solution {
        flex-direction: column;
        padding-bottom: 30px;
    }

    .foodprint-alternative-title-section {
        margin: 20px 0px 20px 0px;
    }

} */

@media only screen and (max-width: 1300px) { 

    .four-categories {
        flex-direction: column;
    }
    .categories-selected-left {
        border-radius: 10px 10px 0px 0px;
    }
    .categories-selected-right {
        border-radius: 0px 0px 0px 0px;
    }
    .category-selected {
        width: auto;
        border-right: none;
    }
    .foodprint-nutritionalvalues {
        flex-direction: column;
    }
    .foodprint-nutritionalvalues-title {
        margin-bottom: 10px;
    }

    .weeklyfoodprint-impactcard {
        margin-top: 20px;
        width: 32%;
    }
    .foodselected-and-editbutton{
        width: 90%;
    }
    .categories-selected {
        width: 90%;
    }
    .weeklyfoodprint-title {
        width: 90%;
    }
    .weeklyfoodprint-cards {
        width: 90%;
    }
    .weeklyfoodprint-support {
        width: 90%;
    }
    .foodprint-solution {
        margin-top: 30px;
        width: 87%;
        max-width: 1165px;
    }
}

@media only screen and (max-width: 1100px) { 

    .arrow-desktop {
        display: none;
    }
    .arrow-mobile {
        display: block;
    }

    .foodprint-solution {
        flex-direction: column;
        padding-bottom: 30px;
    }

    .foodprint-alternative-title-section {
        margin: 20px 0px 20px 0px;
    }

}

@media only screen and (max-width: 950px) { 

    .weeklyfoodprint-cards {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .weeklyfoodprint-impactcard {
        margin-top: 20px;
        width: 90%;
    }

}

@media only screen and (max-width: 700px) { 

    .foodprint-ready {
        font-size: 24px;
        line-height: 20px;
    }
    .food-selected-title {
        font-size: 24px;
        line-height: 20px;
    }
    .edit-foodprint {
        font-size: 24px;
        line-height: 20px;
    }
    .weeklyfoodprint-title {
        font-size: 24px;
        line-height: 20px;
    }
    .weeklyfoodprint-cards {
        margin-top: 0px;
    }

}

@media only screen and (max-width: 500px) { 

    .foodprint-ready {
        font-size: 20px;
    }
    .food-selected-title {
        font-size: 20px;
    }
    .edit-foodprint {
        font-size: 20px;
    }
    .weeklyfoodprint-title {
        font-size: 20px;
    }

    .weeklyfoodprint-impactcard {
        width: 90%;
    }

    .foodprint-solution {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-right: 0px;
        width: 90%;
    }

    .foodselected-and-editbutton {
        width: 90%;
    }
    .categories-selected {
        width: 90%;
    }
    .weeklyfoodprint-title {
        width: 90%;
    }
    .weeklyfoodprint-support {
        width: 90%;
    }
    .weeklyfoodprint-impactcard {
        width: 90%;
    }
}

@media only screen and (max-width: 400px) { 

    .foodprint-ready {
        font-size: 16px;
    }
    .food-selected-title {
        font-size: 16px;
    }
    .edit-foodprint {
        font-size: 16px;
    }
    .weeklyfoodprint-title {
        font-size: 16px;
    }
    
}
.background-datasource {
    background: #222222;
    width: 100%;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 700px;
    margin-top: 50px;
    color: #EBECED;
    flex-wrap: wrap;
}

.img-datasource {
    /* height: 350px; */
    width: 450px;
    margin: 0px 50px 0px 50px;
}

.text-datasource {
    width: 450px;
    margin: 0px 50px 0px 20px;
}

.datasource-title {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
}

.datasource-support {
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin-top: 10px;
}

.datasource-article {
    background: #444444;
    border-radius: 10px;
    padding: 20px;
    margin-top: 10px;
    cursor: pointer;
    border: 1px dotted #828282;
    width: 400px;
}

.datasource-article-text {
    color: #EBECED;
}

.datasource-linksciencepage {
    color: white;
    margin: 10px 0px 10px 0px;
}

@media only screen and (max-width: 550px) {

    .background-datasource {
        padding: 50px 0px 50px 0px;
    }

    .img-datasource {
        width: 80%;
    }

    .text-datasource {
        width: 90%;
        margin: 0px;
    }

    .datasource-article {
        width: 85%;
    }

}
.home-sections {
    display: flex;
    flex-direction: column;
    padding: 0px 300px;
    align-items: center;
}

.home-first-section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 850px;
    margin-top: 50px;
    justify-content: space-between;
}

.home-first-section-text{
    display: flex;
    flex-direction: column;
}

.home-title {
    font-weight: 800;
    font-size: 36px;
    line-height: 49px;
}

.home-text {
    font-size: 20px;
    line-height: 27px;
    margin-top: 10px;
}

.protectland-button {
    color: white;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    background-color: #00BFA6;
    margin: 20px 0px 0px 0px;
    border-radius: 4px;
    border: 2px solid #000000;
    padding: 10px 5px;
    width: 380px;
    cursor: pointer;
}

.home-supporting-text {
    margin-top: 5px;
    font-size: 16px;
    line-height: 22px;
    color: #828282;
}

.home-first-image {
    height: 180px;
}


.home-goal-section {
    background-color: rgba(0, 191, 166, 0.1);
    margin-top: 30px;
    padding: 30px 70px;
}
.goal-status {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 15px;
    width: 700px;
}
.goal-stauts-content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
    .goal-status-content-left {
        display: flex;
        align-items: flex-end;
    }
    .current-number {
        font-weight: 800;
        font-size: 24px;
        line-height: 22px;
        color: #007767;
        margin-right: 6px;
    }
    .goal-description {
        font-size: 16px;
        line-height: 22px;
    }
    .goal-reference {
        font-size: 16px;
        line-height: 22px;
        text-align: right;
    }

    .goal-bar-background {
        width: 740px;
        height: 32px;
        background: #17E8CC;
        border-radius: 50px;
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .goal-bar-progress {
        width: 182px;
        height: 24px;
        background: #007767;
        border-radius: 50px;
        margin-left: 5px;
    }

.section-separation {
    max-width: 1000px;
    margin: 80px 0px;
}

.home-second-section-groups {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 850px;
}
.second-section-group {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.second-section-image {
    height: 180px;
}
.home-second-section-belowgroups {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.third-section-group {
    display: flex;
    align-items: center;
    margin-bottom: 100px;
    flex-wrap: wrap;
    width: 850px;
}
.third-section-group-image {
    height: 230px;
}
.third-section-group-text {
    margin-left: 50px;
    max-width: 430px;
}

.third-section-googlemaps-group {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.googlemapsaddress {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}
.viewinmaps {
    margin-left: 10px;
}
.home-googlemaps-image {
    height: 100px;
    margin-bottom: 10px;
}


.howitworks-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 850px;
}
.howitworks-group {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 200px;
}
.howitworks-image {
    height: 100px;
}

.money-explenation {
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 850px;
}
.onedaycost{
    border-radius: 4px;
    border: 2px solid #000000;
    width: 350px;
    margin-top: 20px;
}
    .onedaycost-text {
        padding: 20px;
    }
    .day-title {
        margin-right: 10px;
    }
    .onedaycost-text-title {
        display: flex;
        align-items: baseline;
    }
    .one-day-price {
        font-size: 36px;
        line-height: 49px;
        text-align: center;
        background: #E3F8F5;
        border-radius: 4px;
        margin-top: 20px;
    }
    .onedaycost-button {
        color: white;
        font-weight: 800;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
        background-color: #00BFA6;
        margin: 10px 0px 0px 0px;
        border-radius: 4px;
        border-top: 2px solid #000000;
        padding: 10px 5px;
        cursor: pointer;
    }
    .oneday-space {
        margin-top: 20px;
    }
.money-spending {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pie-image {
    margin-top: 70px;
    height: 200px;
}

@media only screen and (max-width: 1000px) {

    .home-sections {
        padding: 0px;
    }
    .home-title {
        margin-top: 20px;
    }
    .money-spending-text {
        margin-top: 60px;
    }
    .home-first-section, .home-second-section-groups,
    .third-section-group, .howitworks-section, .money-explenation{
        max-width: 450px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .home-goal-section {
        padding: 30px 30px;
    }
    .protectland-button {
        width: 380px;
    }
    .goal-stauts-content {
        flex-direction: column;
        align-items: flex-start;
    }
    .goal-reference {
        margin-top: 25px;
    }
    .goal-status {
        width: 400px;
    }
    .goal-bar-background {
        width: 480px;
    }
    /* .goal-bar-progress {
        width: 182px;
    } */
    .section-separation {
        max-width: 100%;
    }

    .second-section-group {
        width: 300px;
    }
    .third-section-group-image {
        height: 230px;
    }
    .third-section-group-text {
        margin-left: 50px;
        max-width: 430px;
    }
    .howitworks-group {
        width: 200px;
    }
    .howitworks-image {
        height: 100px;
    }
    .onedaycost{
        width: 350px;
    }
}

@media only screen and (max-width: 540px) {

    .home-first-section, .home-second-section-groups,
    .third-section-group, .howitworks-section, 
    .money-explenation, .onedaycost{
        max-width: 300px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .home-goal-section {
        padding: 10px 10px;
        max-width: 300px;
    }
    /* .goal-stauts-content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    } */
    .goal-status-content-left {
        flex-direction: column;
        align-items: flex-start;
        height: 50px;
    }

    .goal-status {
        width: 300px;
    }
    .goal-bar-background {
        width: 300px;
    }
    /* .goal-bar-progress {
        width: 100px;
    } */
    .protectland-button {
        width: 290px;
    }
    .home-supporting-text {
        font-size: 15px;
    }
    .third-section-group-image {
        height: 130px;
    }

}
