.footer {
    margin-top: 50px;
    padding: 2em;
    text-align: center;
  }
  
    .findUs {
      font-family: 'Quicksand';
      font-weight: normal;
      font-size: 14px;
      line-height: 40px;
    }
  
    .social-section {
      display: flex;
      justify-content: center;
      margin-bottom: 50px;
    }
  
      .social {
        margin: 15px;
        text-align: center;
      }

        .fc {
          margin-left: 0px;
        }

        .insta {
          margin-right: 0px;
        }
  
  .rights {
    font-family: 'Quicksand';
    font-weight: 300;
    font-size: 15px;
    line-height: 40px;
    color: #4F4F4F;
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .modal-button {
    padding: 10px 20px 10px 20px;
    margin-top: 20px;
    font-size: 14px;
  }

@media only screen and (max-width: 460px) {

    .rights {
        font-size: 12px;
    }

    .social {
      margin: 7px;
      text-align: center;
    }

}