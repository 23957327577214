.grams {
  display: flex;
  justify-content: space-between;
}

/* .grams-amount {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
} */

.grams-amount-foodprint {
  display: flex;
}

.nutritional-values {
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-evenly;
  width: 40%;
}

  .nutritional-value {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

@media only screen and (max-width: 900px) {

  .grams {
      display: inline;
      border-bottom: none;
  }

  .nutritional-values {
    justify-content: space-evenly;
    width: 100%;
    margin-top: 20px;
  }
    
}

@media only screen and (max-width: 400px) {

  .nutritional-values {
    font-size: 14px;
  }
    
}