.fb-share-button {
    font-family: 'Quicksand';
    font-weight: bold;
    font-size: 14px;
    line-height: 40px;
    margin-top: 200px;
    color: white;
    background-color: #2F80ED;
    border: 1px dotted #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 280px;
  }
  .fb-share-button-foodprint {
    font-family: 'Quicksand';
    font-weight: bold;
    font-size: 14px;
    line-height: 40px;
    color: white;
    background-color: #2F80ED;
    border: 1px dotted #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 280px;
  }
  
    .fb-share-text {
      color: white;
      font-weight: bold;
      text-align: center;
    }