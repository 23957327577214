.pollutions {
    margin: 80px 20px 0px 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
  
.pollutions-animation {
    animation: moveFromBottom 0.75s ease-in-out;
}

.pollution-grams {
    padding: 30px;
}

.pollution-impact {
    background: #FAFAFA;
    padding: 30px 30px 15px 30px;
}

.pollution-border-top {
    border-top: 1px solid  #E0E0E0;
}

.pollution-border-right {
    border-right: 1px solid  #E0E0E0;
}

.pollution-corner-bottomleft {
    border-radius: 0px 0px 0px 10px;
}

.pollution-corner-bottomright {
    border-radius: 0px 0px 10px 0px;
}

.foodprint-sameas {
    text-align: left;
    padding: 10px 40px 20px 40px;
    background: #FAFAFA;
    border-radius: 0px 0px 10px 10px;
}

@keyframes moveFromBottom {
    0% { transform: translateY(2000px); opacity: 0%; }
    80% { transform: translateY(-10px); }
    100% { transform: translateY(0px); opacity: 100%; }
}

.pollution-card {
    display: flex;
}

.pollution-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
}

.impact-description {
    font-size: 16px;
    line-height: 10px;
    margin-top: 10px;
}

@media only screen and (max-width: 768px) {

    .pollution-card {
        display: inline;
    }

}