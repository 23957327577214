.pink-cover-background {
    background: #BB6BD9;
    width: 100%;
  }

    .mouth-foodprint-button {
        background: #FFFFFF;
        border: 1px dashed #BB6BD9;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #9B51E0;
        padding: 5px 20px 5px 20px;
        cursor: pointer;
    }

    .mouth {
        width: 100%;
        float: right;
        margin-top: -130px;
        margin-bottom: 50px;
    }

    .grey-foodselection-background {
        background-color: #F2F2F2;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

        .cover-support-black {
            color: #444444;
            font-family: 'Quicksand';
            font-weight: normal;
            font-size: 26px;
            line-height: 35px;
        }

    .home-fooddetails-center {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* margin-left: 30px; */
    }

@media only screen and (max-width: 1000px) { 
    
    .mouth {
        margin-top: 0px;
    }

    .home-fooddetails-center {
        margin-left: 0px;
    }
    
}
